import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

const dotNotificationVariants = cva("rounded-full outline outline-paper", {
  variants: {
    notificationKind: {
      default: "bg-primary",
      forceDanger: "bg-red-500",
    },
  },
  defaultVariants: {
    notificationKind: "default",
  },
});

const DotNotification: React.FC<{ className?: string } & VariantProps<typeof dotNotificationVariants>> = ({
  className,
  notificationKind,
}) => <div className={cn(dotNotificationVariants({ notificationKind }), className)} />;

export default DotNotification;
DotNotification.displayName = "DotNotification";

export { DotNotification, dotNotificationVariants };
