import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { HeadingTagType } from "@lexical/rich-text";
import { $isHeadingNode } from "@lexical/rich-text";
import type { TextFormatType } from "lexical";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { cn } from "../../../../lib/utils";
import { Icon } from "../../../atoms/icon";
import { toggleHeading } from "../utils/editor";
import { ADD_ANNOTATION_COMMAND } from "./AnnotationPlugin";
import { useEditorCapabilities } from "./EditorContext";

interface IToolbarButtonProps {
  active?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

function ToolbarButton({ active = false, children, onClick: handleClick }: IToolbarButtonProps): React.JSX.Element {
  return (
    <button
      className={cn(
        "rounded-sm p-1 text-xs hover:bg-muted hover:text-muted-foreground",
        active ? "bg-muted text-muted-foreground" : "text-muted-foreground",
      )}
      type="button"
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

export function FloatingToolbarPlugin(): React.JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const { enableAnnotations } = useEditorCapabilities();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [activeHeading, setActiveHeading] = useState<HeadingTagType | null>(null);
  const [position, setPosition] = useState({ top: -10000, left: -10000 });
  const [isToolbarMounted, setIsToolbarMounted] = useState(false);

  const updatePosition = useCallback((): void => {
    const selection = window.getSelection();
    const toolbar = toolbarRef.current;

    if (!toolbar || !selection || selection.rangeCount === 0 || selection.toString().length === 0) {
      return;
    }

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    if (rect.width === 0 || rect.height === 0) {
      return;
    }

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate initial position (centered above the selection)
    const toolbarWidth = toolbar.offsetWidth;
    const toolbarHeight = toolbar.offsetHeight;

    let left = rect.left + (rect.width - toolbarWidth) / 2;
    let top = rect.top - toolbarHeight - 8;

    // Ensure the toolbar doesn't go off-screen
    // Check left boundary
    if (left < 8) {
      left = 8;
    }
    // Check right boundary
    if (left + toolbarWidth > viewportWidth - 8) {
      left = viewportWidth - toolbarWidth - 8;
    }
    // If toolbar would go above viewport, position it below selection
    if (top < 8) {
      top = rect.bottom + 8;
    }
    // If toolbar would go below viewport, force it above
    if (top + toolbarHeight > viewportHeight - 8) {
      top = rect.top - toolbarHeight - 8;
    }

    setPosition({
      top: top + window.scrollY,
      left: left + window.scrollX,
    });
  }, []);

  // Update position on resize and scroll
  useEffect((): (() => void) => {
    const handleUpdate = (): void => {
      if (isVisible && isToolbarMounted) {
        requestAnimationFrame(() => {
          updatePosition();
        });
      }
    };

    window.addEventListener("resize", handleUpdate);
    window.addEventListener("scroll", handleUpdate, true);

    return () => {
      window.removeEventListener("resize", handleUpdate);
      window.removeEventListener("scroll", handleUpdate, true);
    };
  }, [isVisible, isToolbarMounted, updatePosition]);

  // Handle toolbar mount
  useLayoutEffect(() => {
    if (toolbarRef.current) {
      setIsToolbarMounted(true);
      if (isVisible) {
        requestAnimationFrame(() => {
          updatePosition();
        });
      }
    }
  }, [isVisible, updatePosition]);

  const formatText = useCallback(
    (formatType: TextFormatType) => {
      editor.dispatchCommand(FORMAT_TEXT_COMMAND, formatType);
    },
    [editor],
  );

  const handleBoldClick = useCallback((): void => {
    formatText("bold");
  }, [formatText]);

  const handleItalicClick = useCallback((): void => {
    formatText("italic");
  }, [formatText]);

  const handleOrderedListClick = useCallback((): void => {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  }, [editor]);

  const handleUnorderedListClick = useCallback((): void => {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  }, [editor]);

  const handleLinkClick = useCallback((): void => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const handleAnnotationClick = useCallback((): void => {
    const selection = window.getSelection();

    if (!selection || selection.isCollapsed) return;

    // Save the current selection state
    const range = selection.getRangeAt(0);

    // Restore the selection
    selection.removeAllRanges();
    selection.addRange(range);

    // Update the editor state
    editor.update(() => {
      const currentSelection = $getSelection();

      if (!$isRangeSelection(currentSelection)) return;

      // The plugin's onAnnotationCreate handler will handle the prompt and creation
      editor.dispatchCommand(ADD_ANNOTATION_COMMAND, null);
    });
  }, [editor]);

  const handleHeadingClick = useCallback(
    (headingTag: HeadingTagType) => {
      toggleHeading(editor, headingTag);
    },
    [editor],
  );

  const handleH1Click = useCallback(() => {
    handleHeadingClick("h1");
  }, [handleHeadingClick]);

  const handleH2Click = useCallback(() => {
    handleHeadingClick("h2");
  }, [handleHeadingClick]);

  const handleH3Click = useCallback(() => {
    handleHeadingClick("h3");
  }, [handleHeadingClick]);

  // Handle editor state updates
  useEffect((): (() => void) => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          setIsVisible(false);

          return;
        }

        // Only show toolbar if text is selected
        if (selection.isCollapsed()) {
          setIsVisible(false);

          return;
        }

        // Update text format
        setIsBold(selection.hasFormat("bold"));
        setIsItalic(selection.hasFormat("italic"));

        // Update link format
        const nodes = selection.getNodes();
        const node = nodes[0];
        const parent = node?.getParent();

        setIsLink(parent ? $isLinkNode(parent) : false);

        // Update heading format
        const anchorNode = selection.anchor.getNode();
        const element = anchorNode.getParent();

        if ($isHeadingNode(element)) {
          setActiveHeading(element.getTag());
        } else {
          setActiveHeading(null);
        }

        // Update visibility and position
        setIsVisible(true);
        if (isToolbarMounted) {
          requestAnimationFrame(() => {
            updatePosition();
          });
        }
      });
    });
  }, [editor, isToolbarMounted, updatePosition]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      ref={toolbarRef}
      className="fixed z-[999] flex flex-wrap gap-1 rounded-md border border-border bg-paper p-1 shadow"
      style={{ top: position.top, left: position.left }}
    >
      <ToolbarButton active={activeHeading === "h1"} onClick={handleH1Click}>
        <Icon name="heading-1" />
      </ToolbarButton>
      <ToolbarButton active={activeHeading === "h2"} onClick={handleH2Click}>
        <Icon name="heading-2" />
      </ToolbarButton>
      <ToolbarButton active={activeHeading === "h3"} onClick={handleH3Click}>
        <Icon name="heading-3" />
      </ToolbarButton>

      <div className="h-6 w-px bg-border" />

      <ToolbarButton active={isBold} onClick={handleBoldClick}>
        <Icon name="bold" />
      </ToolbarButton>
      <ToolbarButton active={isItalic} onClick={handleItalicClick}>
        <Icon name="italic" />
      </ToolbarButton>

      <div className="h-6 w-px bg-border" />

      <ToolbarButton onClick={handleOrderedListClick}>
        <Icon name="list-ordered" />
      </ToolbarButton>
      <ToolbarButton onClick={handleUnorderedListClick}>
        <Icon name="list" />
      </ToolbarButton>

      <div className="h-6 w-px bg-border" />

      <ToolbarButton active={isLink} onClick={handleLinkClick}>
        <Icon name="link" />
      </ToolbarButton>

      {enableAnnotations ? (
        <>
          <div className="h-6 w-px bg-border" />
          <ToolbarButton active={false} onClick={handleAnnotationClick}>
            <Icon name="message-square" />
          </ToolbarButton>
        </>
      ) : null}
    </div>
  );
}
