import type { EditorConfig, NodeKey, RangeSelection, SerializedTextNode } from "lexical";
import { $createTextNode, $getSelection, $isRangeSelection, TextNode } from "lexical";

export interface ISerializedMentionNode extends SerializedTextNode {
  mentionId: string;
  mentionName: string;
  type: "mention";
  version: 1;
}

export class MentionNode extends TextNode {
  __mentionId: string;
  __mentionName: string;

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mentionId, node.__mentionName, node.__key);
  }

  constructor(mentionId: string, mentionName: string, key?: NodeKey) {
    super(mentionName, key);
    this.__mentionId = mentionId;
    this.__mentionName = mentionName;
  }

  getMentionId(): string {
    return this.__mentionId;
  }

  getMentionName(): string {
    return this.__mentionName;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);

    dom.className = "bg-accent rounded px-1.5 py-0.5";
    dom.contentEditable = "false"; // Make mention non-editable

    return dom;
  }

  exportJSON(): ISerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionId: this.__mentionId,
      mentionName: this.__mentionName,
      type: "mention",
      version: 1,
    };
  }

  static importJSON(serializedNode: ISerializedMentionNode): MentionNode {
    return new MentionNode(serializedNode.mentionId, serializedNode.mentionName);
  }

  // Override to indicate this is not a simple text node
  isSimpleText(): boolean {
    return false;
  }

  // Override to prevent direct text content changes
  isSegmented(): boolean {
    return true;
  }

  // Override to convert to text node when content changes
  getTextContent(): string {
    const currentContent = super.getTextContent();
    const selection = $getSelection();

    if (currentContent !== this.__mentionName && $isRangeSelection(selection)) {
      // Get the text directly from the DOM to avoid recursion
      const text = this.__text;
      const textNode = $createTextNode(text);
      const offset = selection.anchor.offset;

      this.replace(textNode);
      textNode.select(offset, offset);

      return text;
    }

    return currentContent;
  }

  insertNewAfter(_selection?: RangeSelection): null | TextNode {
    const textNode = $createTextNode("");

    this.insertAfter(textNode);
    textNode.select();

    return textNode;
  }

  // Handle selection at offset 0 to allow insertion before mention
  selectPrevious(anchorOffset?: number, focusOffset?: number): RangeSelection {
    const textNode = $createTextNode("");

    this.insertBefore(textNode);

    return textNode.select(anchorOffset ?? 0, focusOffset ?? 0);
  }

  // Override to handle removal (including cut operations)
  remove(preserveEmptyParent?: boolean): void {
    const textNode = $createTextNode(this.__mentionName);

    this.replace(textNode);
    textNode.remove(preserveEmptyParent);
  }

  // Override splitText to handle splitting around mentions
  splitText(...splitOffsets: number[]): Array<TextNode> {
    const textNodes: Array<TextNode> = [];

    // Create an empty text node before the mention if splitting at the start
    if (splitOffsets.includes(0)) {
      const beforeNode = $createTextNode("");

      this.insertBefore(beforeNode);
      textNodes.push(beforeNode);
    }

    textNodes.push(this);

    // Create an empty text node after the mention if splitting at the end
    if (splitOffsets.includes(this.__text.length)) {
      const afterNode = $createTextNode("");

      this.insertAfter(afterNode);
      textNodes.push(afterNode);
    }

    return textNodes;
  }
}

export function $createMentionNode(mentionId: string, mentionName: string): MentionNode {
  return new MentionNode(mentionId, mentionName);
}
