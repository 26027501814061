import type { HeadingTagType } from "@lexical/rich-text";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import type { LexicalEditor } from "lexical";
import { $createParagraphNode, $createTextNode, $getSelection, $isRangeSelection } from "lexical";

export function toggleHeading(editor: LexicalEditor, headingTag: HeadingTagType): void {
  editor.update(() => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) return;

    const anchorNode = selection.anchor.getNode();
    const parent = anchorNode.getParent();

    // Get text content from the parent node if it exists, otherwise from selection
    const textContent = parent ? parent.getTextContent() : selection.getTextContent();

    // Check if we're already in a heading of the same type
    const isCurrentHeading = $isHeadingNode(parent) && parent.getTag() === headingTag;

    // Create the new node (either paragraph or heading)
    const newNode = isCurrentHeading ? $createParagraphNode() : $createHeadingNode(headingTag);

    // Create and append the text content
    if (textContent.length > 0) {
      const textNode = $createTextNode(textContent);

      newNode.append(textNode);
    }

    // Replace the old node with the new one
    if (parent) {
      parent.replace(newNode);
    } else {
      anchorNode.replace(newNode);
    }

    // Select the new node
    newNode.select();
  });
}
