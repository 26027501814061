import * as React from "react";
import { useCallback } from "react";

import { cn } from "../../lib/utils";
import { EmailComposer, type IEmailComposer } from "../molecules/email/email-composer";
import { Eyebrow } from "./eyebrow";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

type ILiveFieldIndicatorSimple = {
  type: "live" | "ai" | "lookup";
  email?: never;
};

type ILiveFieldIndicatorEmail = {
  type: "email";
  email: IEmailComposer;
};

type ILiveFieldIndicator = {
  className?: string;
} & (ILiveFieldIndicatorSimple | ILiveFieldIndicatorEmail);

export const LiveFieldIndicator: React.FC<ILiveFieldIndicator> = ({ className, type, email }) => {
  const renderContent = useCallback(() => {
    switch (type) {
      case "ai": {
        return (
          <TooltipContent>
            <p>Computes automatically using AI</p>
          </TooltipContent>
        );
      }
      case "lookup": {
        return (
          <TooltipContent>
            <p>Computes automatically using lookup</p>
          </TooltipContent>
        );
      }

      case "email": {
        return (
          <TooltipContent asChild>
            <EmailComposer className="min-w-96 p-0" {...email} />
          </TooltipContent>
        );
      }

      case "live": {
        return (
          <TooltipContent>
            <p>Computes automatically</p>
          </TooltipContent>
        );
      }
    }
  }, [type, email]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Eyebrow className={cn("leading-6", className)} variant="secondary">
          {type}
        </Eyebrow>
      </TooltipTrigger>
      {renderContent()}
    </Tooltip>
  );
};
