import "../wdyr";
import "@archetype/ui/globals.css";

import { builderTrpc as trpc } from "@archetype/trpc-react";
import { Toaster } from "@archetype/ui";
import { generateTitle } from "@archetype/utils";
import { ClerkProvider } from "@clerk/nextjs";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import type { NextPage } from "next";
import localFont from "next/font/local";
import Head from "next/head";
import { useEffect, useState } from "react";

import { AuthenticationProvider } from "../components/auth/AuthenticationProvider";
import { UserMonitoringProvider } from "../components/monitoring/UserMonitoringProvider";
import DatadogInit from "../datadog-init";
import { PostHogProvider } from "../providers";

// TODO @ShaunSpringer make this reusable -- its currently in 4 places (storybooks and apps)
const diatypeFont = localFont({
  src: [
    {
      path: "../../public/fonts/diatype/ABCDiatype-Light.woff",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Regular.woff",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Medium.woff",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Medium.woff",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Bold.woff",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Heavy.woff",
      weight: "800",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Black.woff",
      weight: "900",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype/ABCDiatype-Ultra.woff",
      weight: "1000",
      style: "normal",
    },
  ],
  variable: "--font-diatype",
  display: "swap",
  preload: true,
});

const diatypeFontMono = localFont({
  src: [
    {
      path: "../../public/fonts/diatype-mono/ABCDiatypeMono-Regular.woff",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype-mono/ABCDiatypeMono-Medium.woff",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/diatype-mono/ABCDiatypeMono-Bold.woff",
      weight: "700",
      style: "normal",
    },
  ],
  variable: "--font-diatype-mono",
  display: "swap",
  preload: true,
});

function App<T extends object>({ pageProps, Component }: { pageProps: T; Component: NextPage<T> }): React.ReactNode {
  const [isServer, setIsServer] = useState(true);

  useEffect(() => {
    setIsServer(false);
  }, []);

  if (isServer) {
    return null;
  }

  return (
    <div suppressHydrationWarning className="flex h-max min-h-dvh">
      <Head>
        <title>{generateTitle()}</title>
        <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" />
      </Head>
      <style global jsx>
        {`
          :root {
            --font-diatype: ${diatypeFont.style.fontFamily};
            --font-diatype-mono: ${diatypeFontMono.style.fontFamily};
          }

          #__next {
            min-height: 100dvh;
          }
        `}
      </style>
      <DatadogInit />
      <PostHogProvider>
        <ClerkProvider {...pageProps}>
          <UserMonitoringProvider>
            <AuthenticationProvider>
              {typeof window === "undefined" ? null : <Component {...pageProps} />}
            </AuthenticationProvider>
          </UserMonitoringProvider>
        </ClerkProvider>
        <Toaster />
        <Analytics />
        <SpeedInsights />
      </PostHogProvider>
    </div>
  );
}

export default trpc.withTRPC(App);
