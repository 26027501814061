import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import type { InitialConfigType } from "@lexical/react/LexicalComposer";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ParagraphNode, TextNode } from "lexical";

import { AnnotationNode } from "./nodes/AnnotationNode";

function onError(error: Error): void {
  // Log error to error reporting service
  throw error;
}

export const defaultTheme = {
  paragraph: "mb-1",
  text: {
    base: "text-ink",
    bold: "font-bold",
    italic: "italic",
    underline: "underline",
    strikethrough: "line-through",
    underlineStrikethrough: "underline line-through",
  },
  heading: {
    h1: "text-3xl font-bold my-1",
    h2: "text-2xl font-bold my-1",
    h3: "text-xl font-bold my-1",
    h4: "text-lg font-bold my-1",
    h5: "text-base font-bold my-1",
    h6: "text-sm font-bold my-1",
  },
  list: {
    ol: "list-decimal pl-6",
    ul: "list-disc pl-6",
    listitem: "mb-1 ml-1",
    nested: {
      listitem: "mb-1",
    },
  },
  link: "text-primary",
  quote: "border-l-4 border-muted pl-4 my-1 italic",
  code: "bg-muted px-1 py-0.5 font-mono my-1",
};

export const defaultConfig: InitialConfigType = {
  namespace: "MarkdownEditor",
  theme: defaultTheme,
  onError,
  nodes: [
    ListNode,
    ListItemNode,
    LinkNode,
    AutoLinkNode,
    HeadingNode,
    QuoteNode,
    TextNode,
    ParagraphNode,
    CodeNode,
    CodeHighlightNode,
    AnnotationNode,
  ],
} as const;
