import { createContext, useContext } from "react";

interface IEditorCapabilities {
  enableAnnotations: boolean;
}

export const EditorCapabilitiesContext = createContext<IEditorCapabilities>({
  enableAnnotations: false,
});

export function useEditorCapabilities(): IEditorCapabilities {
  return useContext(EditorCapabilitiesContext);
}
