"use client";

import { Command as CommandPrimitive } from "cmdk";
import { isEqual } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";

import { mergeRefs } from "../../lib/mergeRefs";
import { cn } from "../../lib/utils";
import { Loader } from "../molecules/loader/Loader";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "./command";
import { Icon } from "./icon";
import { inputVariants } from "./input";
import { Popover, PopoverContent, PopoverPortal, PopoverTrigger } from "./popover";
import { Spinner } from "./spinner";

export interface IComboboxOption<T extends string = string> {
  value: T;
  label: string;
  disabled?: boolean;
}

interface ICombobox<T extends string = string> {
  options: IComboboxOption<T>[];
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  triggerClassName?: string;
  isLoading?: boolean;
  onSearch?: (value: string) => void;
  onCreateItem?: (value: string) => void;
  small?: boolean;
  loadingIndicator?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
  value?: IComboboxOption<T> | null;
  onChange?: (option: IComboboxOption<T> | null) => void;
}

function Combobox<T extends string = string>({
  className,
  value,
  onChange,
  options,
  placeholder,
  disabled,
  triggerClassName,
  isLoading,
  onSearch,
  onCreateItem,
  small,
  loadingIndicator,
  ref,
}: ICombobox<T>): React.ReactElement {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const previousValue = usePrevious(value);

  useEffect(() => {
    if (value != null && !isEqual(value, previousValue)) {
      setInputValue(value.label);
    }
  }, [value, previousValue]);

  const handleSelect = useCallback(
    (option: IComboboxOption<T>) => (): void => {
      onChange?.(option);
      setInputValue(option.label);
      setOpen(false);
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (newValue: string) => {
      setInputValue(newValue);
      setOpen(true);
      onSearch?.(newValue);
    },
    [onSearch],
  );

  const handleClear = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onChange?.(null);
      setInputValue("");
    },
    [onChange],
  );

  const handleCreateItem = useCallback(() => {
    onCreateItem?.(inputValue);
    setOpen(false);
  }, [inputValue, onCreateItem]);

  const handleClickOutside = useCallback((event: MouseEvent | TouchEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside);

    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleTriggerClick = useCallback(() => {
    if (disabled == null || !disabled) {
      setOpen(true);
      inputRef.current?.focus();
    }
  }, [disabled]);

  const handleInputFocus = useCallback(() => {
    setOpen(true);
  }, []);

  // Search and filter dont interact well in Command
  const shouldFilter = onSearch == null;
  const creatable = onCreateItem != null;

  const showAllOptions = value != null && inputValue === value.label;

  // Disable CMDK filtering when external search is used
  const filterFn = useCallback((_searchValue: string, _query: string): number => 1, []);

  return (
    <Command
      ref={dropdownRef}
      className={cn("h-auto overflow-visible bg-transparent", className)}
      filter={onSearch != null ? filterFn : undefined}
      shouldFilter={shouldFilter ? !showAllOptions : undefined}
      value={value?.value}
    >
      <Popover open={open}>
        <PopoverTrigger asChild>
          <div
            className={cn(
              inputVariants({ size: small === true ? "small" : "default" }),
              triggerClassName,
              "flex items-center",
            )}
            onClick={handleTriggerClick}
          >
            <CommandPrimitive.Input
              ref={ref == null ? inputRef : mergeRefs(ref, inputRef)}
              className={cn(
                "min-w-0 flex-1 bg-transparent outline-none placeholder:text-placeholder",
                inputValue === "" && "text-placeholder",
              )}
              disabled={disabled === true}
              placeholder={placeholder}
              value={inputValue}
              onFocus={handleInputFocus}
              onValueChange={handleInputChange}
            />
            <span className="ml-2 flex shrink-0 items-center gap-x-1 text-muted-foreground">
              {value ? <Icon className="cursor-pointer" name="x" role="button" onClick={handleClear} /> : null}
              {isLoading === true ? <Spinner /> : <Icon className="shrink-0" name="chevron-down" />}
            </span>
          </div>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent
            align="start"
            className="max-h-[300px] w-full min-w-72 p-0 shadow outline-none animate-in"
            style={{ width: "var(--radix-popover-trigger-width)" }}
          >
            <CommandList className="w-full">
              {isLoading === true ? (
                <div className="flex items-center justify-center p-4">
                  {loadingIndicator != null ? loadingIndicator : <Loader />}
                </div>
              ) : (
                <>
                  {creatable ? (
                    <CommandGroup>
                      <CommandItem
                        className="cursor-pointer"
                        value={`create-${inputValue}`}
                        onSelect={handleCreateItem}
                      >
                        <span className="flex items-center gap-x-1">
                          <Icon name="plus" />
                          <span>Create new</span>
                        </span>
                      </CommandItem>
                    </CommandGroup>
                  ) : null}
                  <CommandGroup>
                    {options.map((option) => (
                      <CommandItem
                        key={String(option.value)}
                        className="cursor-pointer"
                        value={String(option.value)}
                        onSelect={handleSelect(option)}
                      >
                        <Icon
                          className={cn("mr-2 size-4", option.value === value?.value ? "opacity-100" : "opacity-0")}
                          name="check"
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                  <CommandEmpty>No options found</CommandEmpty>
                </>
              )}
            </CommandList>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </Command>
  );
}

export { Combobox };
